import api from '@/config/api'
import { getBaseHost } from '@/helper'
import { LoginOptions } from '@/type/auth'
import { PageParams, Result, ResultAny, ResultBoolean } from '@/type/common'
import { ResultWallet } from '../topup/types'
import {
  FeedbackOption,
  ResetOption,
  ResultTokenType,
  ResultUserList,
  ResultUserType,
  UserType,
  optionsEmailCode
} from './types'

const apiHost = getBaseHost(process.env.NODE_ENV)

export const exsits = async (options: optionsEmailCode): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.post(`${apiHost}/exsits`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const login = async (options: LoginOptions): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/login`, options)
    return response
  } catch (error: any) {
    return error
  }
}
export const getInfo = async (): Promise<ResultUserType> => {
  try {
    const response: ResultUserType = await api.get(`${apiHost}/getInfo`)
    return response
  } catch (error: any) {
    return error
  }
}

export const register = async (options: UserType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/register`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const resetPwdByEmail = async (options: ResetOption): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/resetPwdByEmail`, options)
    return response
  } catch (error: any) {
    return error
  }
}
// /portal/feedback post
export const feedback = async (options: FeedbackOption): Promise<Result> => {
  try {
    const response: Result = await api.post(`${apiHost}/portal/feedback`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const captchaEmail = async (email: string): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.get(`${apiHost}/captchaEmail`, {
      params: {
        email: email
      }
    })
    return response
  } catch (error: any) {
    return error
  }
}

// 修改用户 put /system/user/profile
export const updateProfile = async (options: {
  lastName: string
  firstName: string
  phonenumber: string
}): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 修改密码 /system/user/profile/updatePwd
export const updatePwd = async (options: { password: string }): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/updatePwd`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 线下查找获取用户 /offline/user/list
export const offlineUserList = async (params: PageParams): Promise<ResultUserList> => {
  try {
    const response: ResultUserList = await api.get(`${apiHost}/offline/user/list`, {
      params: params
    })
    return response
  } catch (error: any) {
    return error
  }
}

//线下添加用户  /offline/add-user
export const offlineAddUser = async (options: {
  username: string
  email: string
  lastName: string
  firstName: string
  phonenumber: string
  userType: string
  roleKey: string
}): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.post(`${apiHost}/offline/add-user`, options)
    return response
  } catch (error: any) {
    return error
  }
}

//是否VIP接口  /app/user/interests/check/{userId}
export const checkIsVip = async (userId: string): Promise<ResultAny> => {
  try {
    const response: ResultAny = await api.get(`${apiHost}/app/user/interests/check/${userId}`)
    return response
  } catch (error: any) {
    return error
  }
}

//获取指定用户账号钱包余额 /offline/account/balance
export const getAccountBalance = async (userId: string): Promise<ResultWallet> => {
  try {
    const response: ResultWallet = await api.get(`${apiHost}/offline/account/balance/${userId}`)
    return response
  } catch (error: any) {
    return error
  }
}
