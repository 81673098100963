import { ReactComponent as LogoSVG } from '@/asset/svg/Transparent.svg'
import { ReactComponent as DownwardSVG } from '@/asset/svg/downward.svg'
import { ReactComponent as LanguageSVG } from '@/asset/svg/language.svg'
import { ReactComponent as MenuSVG } from '@/asset/svg/menu.svg'
import { ReactComponent as OuttaBoundsSVG } from '@/asset/svg/title.svg'
import AuthContext from '@/context/AuthContext'
import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import useStatePopup from '@/hook/useStatePopup'
import { PopUpStore, PopupSelector } from '@/store/PopupStore'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Menu from './Menu'

const Header: React.FC<PropType> = () => {
  const menuPopupVisible = useStatePopup(PopupSelector.menuPopupVisible)
  const { size, overSize } = useMediaBreakpoint()
  const auth = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const toggleMenu = () => {
    PopUpStore.setMenuPopupVisible(!menuPopupVisible)
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const handleSelect = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem('lang', language)
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(/\/(en|zh)\//, `/${language}/`)
    navigate(newPath)
    setIsOpen(false)
  }

  useEffect(() => {
    if (overSize('lg')) {
      PopUpStore.setMenuPopupVisible(true)
    } else {
      PopUpStore.setMenuPopupVisible(false)
    }
  }, [size])

  const logout = () => {
    auth.logout()
    navigate('/')
  }
  return (
    <>
      <div className="fixed z-50 flex h-20 w-full items-center justify-between bg-[#ffffff] px-2 sm:px-6">
        <div className="flex items-center">
          {!menuPopupVisible && <MenuSVG onClick={toggleMenu} fill="#5c5c5c" className="lg:hidden" />}
          <LogoSVG className="ml-auto h-10 sm:ml-28" />
          <OuttaBoundsSVG className="ml-1 w-28 sm:ml-4 sm:w-40" />
        </div>
        <div className="relative flex items-center">
          <div className="flex cursor-pointer items-center hover:text-[#f7931a]" onClick={toggleDropdown}>
            <LanguageSVG className="mr-3 h-6 w-6" fill="#5c5c5c" />
            {i18n.language === 'zh' ? t('btn.chinese') : t('btn.english')}
            <DownwardSVG className="ml-2 h-6 w-6" fill="#5c5c5c" />
          </div>
          {/* 下拉菜单 */}
          {isOpen && (
            <div className="absolute left-0 top-full z-20 mt-2 w-40 rounded-md bg-[#000000]/80 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="py-1 text-center" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-white hover:text-[#f7931a]"
                  onClick={() => handleSelect('zh')}
                >
                  {t('btn.chinese')}
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-white hover:text-[#f7931a]"
                  onClick={() => handleSelect('en')}
                >
                  {t('btn.english')}
                </a>
              </div>
            </div>
          )}
          {/* 用户名显示 */}
          <p className="ml-2 cursor-pointer hover:text-[#f7931a]" onClick={logout}>
            {t('btn.loginOut')}
          </p>
          <p className="ml-2">{auth.user?.userName}</p>
        </div>
      </div>

      <Menu isOpen={menuPopupVisible} onClose={toggleMenu} />
    </>
  )
}

interface PropType {}

export default React.memo(Header)
