import { useNavigate } from 'react-router-dom'

import useRuntime from './useRuntime'

const useLinkTo = () => {
  const { lang } = useRuntime()
  const navigate = useNavigate()

  const getLink = (href: string = '') => {
    if (href.indexOf('/') !== 0) {
      return href
    }

    const basePath = lang ? `/${lang}/` : ''
    const link = (basePath + href).replace('//', '/')

    return link
  }

  const linkTo = (href: string = '') => {
    console.log('getLink(href)', getLink(href))
    navigate(getLink(href))
  }

  return {
    getLink,
    linkTo
  }
}

export default useLinkTo
