import { FC } from 'react'
import { useTranslation } from 'react-i18next'

// 定义类型接口
interface InputProps {
  value: string // 输入框的值
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  placeholder?: string // 输入框的占位符，可选属性
  className?: string // 额外的 CSS 类名，可选属性
  labelClassName?: string // 输入框的标题 CSS 类名，可选属性
  label?: string // 输入框的标题
  error?: string | null // 错误信息
  type?: string
  size?: 'normal' | 'large' // 输入框的尺寸
  // 输入框下面的文字提示，可选属性
  bottomText?: string
  as?: 'input' | 'textarea'
}

const Input: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  labelClassName,
  label,
  type = 'text',
  error,
  size = 'normal',
  bottomText,
  as = 'input' // 默认渲染 input
}) => {
  const { t } = useTranslation()
  const errorMsg = error === 'required' ? (bottomText || label) + t('error.required') : t(`error.${error}` || '')
  const sizeClasses = size === 'large' ? 'text-lg h-14' : 'text-sm h-9'
  return (
    <div className="input-wrapper">
      {label && <label className={`whitespace-nowrap text-[#bbbbbb] ${sizeClasses} ${labelClassName}`}>{label}</label>}
      {as === 'textarea' ? (
        <textarea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`w-full rounded border bg-[#ffffff] p-2 text-black ${sizeClasses} focus:outline-none ${error ? 'border-red-500' : 'border-[#bbbbbb]'} ${className}`}
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`w-full rounded border bg-[#ffffff] p-2 text-black ${sizeClasses} focus:outline-none ${error ? 'border-red-500' : 'border-[#bbbbbb]'} ${className}`}
        />
      )}
      {bottomText && <div className="text-sm text-white">{bottomText}</div>}
      <div className="mt-1 min-h-2">{error && <p className="text-sm text-red-500">{errorMsg}</p>}</div>
    </div>
  )
}

export default Input
