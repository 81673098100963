import LinkEnhance from '@/component/LinkEnhance'
import type { NavItemType } from '@/type/common'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NavItem: React.FC<PropType> = ({ item, onClose, onShowMore }) => {
  const { t } = useTranslation()
  const { name, icon, url, active, children, isHot } = item
  return children ? (
    <></>
  ) : (
    <>
      <LinkEnhance className="group/item flex items-center justify-center text-text-dark" href={url}>
        <div
          onClick={onClose}
          className={`mb-4 flex h-auto w-full flex-col items-center justify-center leading-10 ${active ? ' text-[#333333]' : 'border-transparent text-[#333333] '}`}
        >
          <div
            className={`flex w-fit items-center justify-center px-5 py-1 transition-all ${
              active ? 'rounded-full bg-[#f1dcc3] ' : ' p-0'
            }`}
          >
            <img src={require(`@/asset/svg/${icon}`)} className="cus_menu-icon aspect-square h-[20px]" />
          </div>
          <div className=" flex items-center justify-center whitespace-normal   group-hover/item:opacity-70 ">
            {t(`menu.${name}`)}
          </div>
        </div>
      </LinkEnhance>
    </>
  )
}

interface PropType {
  item: NavItemType
  onClose: () => void
  onShowMore?: (name: string) => void
}

export default NavItem
