export const EVENT_NAMESPACE = {
  POPUP: 'popup'
}

export const testImg =
  'https://images.ctfassets.net/kodt9nlyw9xc/gT6aDdzm40ZpfdInXgowo/cb55a798709d0141b1b808778c48c99c/TG-CPA-2023-18056-YesQueen-RGB-1920x1080.JPEG?fm=webp&q=75&w=3840&h=2160'

export const WeekDays = [
  'book:week.monday',
  'book:week.tuesday',
  'book:week.wednesday',
  'book:week.thursday',
  'book:week.friday',
  'book:week.saturday',
  'book:week.sunday'
]

export const getStatusCode = (status: number) => {
  switch (status) {
    case -2:
      return 'book:table.status1'
    case -1:
      return 'book:table.status2'
    case 0:
      return 'book:table.status3'
    case 1:
      return 'book:table.status4'
    case 2:
      return 'book:table.status5'
    case 3:
      return 'book:table.status6'
    default:
      return 'status'
  }
}
export const createStatusOptions = (t: any) => {
  return [
    { label: t('book:table.all'), value: '' },
    { label: t('book:table.status1'), value: -2 },
    { label: t('book:table.status2'), value: -1 },
    { label: t('book:table.status3'), value: 0 },
    { label: t('book:table.status4'), value: 1 },
    { label: t('book:table.status5'), value: 2 }
  ]
}

export const getTargetType = (status: number) => {
  switch (status) {
    case 1:
      return 'book:table.target1'
    case 2:
      return 'book:table.target2'
    default:
      return 'targetType'
  }
}
export const getbusinessCode = (status: string) => {
  switch (status) {
    case 'RECHARGE':
      return 'book:table.businessCodeRECHARGE'
    case 'VIP':
      return 'book:table.businessCodeVIP'
    case 'BOOKING':
      return 'book:table.businessCodeBOOKING'
    case 'REFUND':
      return 'book:table.businessCodeREFUND'
    default:
      return ''
  }
}

export const getPaymentChannel = (status: string) => {
  switch (status) {
    case 'SQUARE':
      return 'book:table.SQUARE'
    case 'ACCOUNT':
      return 'book:table.ACCOUNT'
    case 'VIP':
      return 'book:table.VIP'
    default:
      return ''
  }
}
