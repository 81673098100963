import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import MenuList from '@/component/Nav/MenuList'
import React from 'react'

interface MenuProps {
  isOpen: boolean
  onClose: () => void
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  return (
    <aside
      className={`fixed left-0 top-0 z-[100] h-screen w-28 bg-[#f3f7fa] pt-10 transition-transform lg:translate-x-0 ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <button onClick={onClose} className="absolute right-4 top-4 text-white lg:hidden ">
        <CloseSVG className="h-7 w-7" />
      </button>
      <MenuList onClose={onClose} />
    </aside>
  )
}

export default React.memo(Menu)
