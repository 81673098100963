import { getInfo } from '@/server/user'
import { UserType } from '@/server/user/types'
import {
  AccountRecoverOptions,
  AuthContextType,
  AuthStatus,
  LoginOptions,
  RegisterOptions,
  UpdateUserOptions
} from '@/type/auth'
import { useMemo, useState } from 'react'

const useProvideAuth = (): AuthContextType => {
  const [status, setStatus] = useState<AuthStatus>('unready')
  const [user, setUser] = useState<UserType | null>(null)

  const getUserInfo = async (): Promise<void> => {
    const res: any = await getInfo()
    if (res.code === 200) {
      const roleKeys: string = res.data.user?.roles.map((role: any) => role.roleKey).join(',')
      const userWithRoles = {
        ...res.data.user,
        roleKey: roleKeys
      }
      console.log(userWithRoles)
      setUser(userWithRoles)
      setStatus('authorized')
    } else {
      setStatus('unauthorized')
    }
  }

  // 用户认证初始化
  const init = async () => {
    if (localStorage.getItem('Authorization') === null) {
      setStatus('unauthorized')
      console.info('auth status: unauthorized')
      return
    }
    await getUserInfo()
  }

  // 登录
  const login = async (options: LoginOptions) => {
    // 普通帐密登录
    // 获取用户信息
    await getUserInfo()
  }

  // 登出
  const logout = () => {
    localStorage.removeItem('Authorization')
    setUser(null)
    setStatus('unauthorized')
    console.info('auth status: unauthorized')
  }

  // 注册
  const registe = async (options: RegisterOptions) => {
    // 用户注册
    // 获取用户信息
    await getUserInfo()
  }

  // 找回密码
  const accountRecover = async (options: AccountRecoverOptions) => {
    // await TGASDK.module.UC.service.resetPassword(options)
  }

  // 更新用户信息
  const updateUser = async (options: UpdateUserOptions) => {
    // 获取用户信息
    await getUserInfo()
  }

  return useMemo(
    () => ({
      status,
      user,
      init,
      login,
      logout,
      registe,
      accountRecover,
      updateUser,
      getUserInfo
    }),
    [status, user]
  )
}

export default useProvideAuth
