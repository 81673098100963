import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import React from 'react'

// 定义类型接口
interface ModalProps {
  visible: boolean // 控制 Modal 是否可见
  onClose: () => void // 关闭 Modal 的回调函数
  children: React.ReactNode // Modal 内容
  width?: number
}

const Modal: React.FC<ModalProps> = ({ visible, onClose, children, width = 510 }) => {
  if (!visible) return null

  return (
    <div className=" fixed inset-0 z-[999] flex  items-start justify-center bg-black bg-opacity-50">
      <div
        className={`relative top-0 rounded-md bg-[#e6e6e6] px-[5%] py-[4%] shadow-lg sm:mt-20 sm:px-10 sm:py-8`}
        style={{ width: `${width}px` }}
      >
        <button
          className="absolute right-4 top-4 p-1 text-gray-500 hover:text-gray-800 focus:outline-none"
          onClick={onClose}
        >
          <CloseSVG className="h-6 w-6" fill="black" />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
