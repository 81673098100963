import React from 'react'
import { Navigate, type RouteObject } from 'react-router-dom'

import MainLayout from '@/component/layout/MainLayout'
import RedirectLayout from '@/component/layout/RedirectLayout'

const AuthRequired = React.lazy(() => import('@/component/AuthRequired'))

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <RedirectLayout />
      },
      {
        path: ':lang/login',
        lazy: async () => {
          return {
            Component: (await import('../page/Login')).default
          }
        }
      },
      {
        path: ':lang/',
        index: true,
        element: <Navigate to="login" replace />
      },
      {
        path: ':lang/',
        element: <MainLayout />,
        children: [
          {
            path: 'orders/',
            lazy: async () => {
              return {
                Component: (await import('../page/Home')).default
              }
            }
          },
          {
            path: 'bookings/',
            lazy: async () => {
              return {
                Component: (await import('../page/Bookings')).default
              }
            }
          },
          {
            path: 'memberships/',
            lazy: async () => {
              return {
                Component: (await import('../page/Memberships')).default
              }
            }
          },
          {
            path: 'topups/',
            lazy: async () => {
              return {
                Component: (await import('../page/Topups')).default
              }
            }
          },
          {
            path: 'purchases/',
            lazy: async () => {
              return {
                Component: (await import('../page/Purchases')).default
              }
            }
          },
          {
            path: 'orders/bookings/',
            lazy: async () => {
              return {
                Component: (await import('../page/Order/Bookings')).default
              }
            }
          },
          {
            path: 'orders/memberships/',
            lazy: async () => {
              return {
                Component: (await import('../page/Order/Memberships')).default
              }
            }
          },
          {
            path: 'orders/purchases/',
            lazy: async () => {
              return {
                Component: (await import('../page/Order/Purchases')).default
              }
            }
          },
          {
            path: 'orders/topups/',
            lazy: async () => {
              return {
                Component: (await import('../page/Order/Topups')).default
              }
            }
          }
        ]
      },
      {
        path: '*',
        element: <RedirectLayout />
      }
    ]
  }
]

export default routes
