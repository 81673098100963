import React, { useContext } from 'react'

import { ReactComponent as LeftSVG } from '@/asset/svg/left.svg'
import menus from '@/config/menu.json'
import AuthContext from '@/context/AuthContext'
import type { NavItemType } from '@/type/common'
import { useTranslation } from 'react-i18next'
import NavItem from '../NavItem'

const MenuList: React.FC<PropType> = ({ onClose }) => {
  const path = window.location.pathname
  const auth = useContext(AuthContext)
  const { t } = useTranslation()
  const section = path.split('/').filter(Boolean)[1]
  const [menuList, setMenuList] = React.useState<NavItemType[]>(menus as NavItemType[])
  const [showBack, setShowBack] = React.useState(false)
  const showMore = (name: string) => {
    const targetItem = menuList.find(item => item.name === name)
    if (targetItem && targetItem.children) {
      setMenuList(targetItem.children)
      setShowBack(true)
    }
  }

  const handleBack = () => {
    setMenuList(menus as NavItemType[])
    setShowBack(false)
  }
  return (
    <>
      {showBack && (
        <div className="group/item">
          <div
            className=" ml-4 flex cursor-pointer transition-all duration-150 ease-in-out group-hover:visible group-hover/item:translate-x-2 group-hover/item:opacity-70"
            onClick={handleBack}
          >
            <LeftSVG className="h-6 w-6 " fill="#ffffff" />
            <p className="ml-2 text-white">{t('btn.back')}</p>
          </div>
        </div>
      )}
      {menuList.map((item, index) => {
        const newItem = { ...item, active: item.name === section }
        if (item.auth) {
          return auth.user && <NavItem item={newItem} key={index} onClose={onClose} onShowMore={showMore} />
        } else {
          return <NavItem item={newItem} key={index} onClose={onClose} onShowMore={showMore} />
        }
      })}
    </>
  )
}

interface PropType {
  onClose: () => void
}

export default MenuList
